import React from "react";
import MyModal from "../../modal";

import SlickSlider from "../../slick-slider";

// import img1 from "./images/solidWaste-modal-img/1.jpg";
// import img2 from "./images/solidWaste-modal-img/2.jpg";
import img3 from "./images/solidWaste-modal-img/3.jpg";
// import img4 from "./images/solidWaste-modal-img4.png";

const SolidModal = ({ show, handleOpenClose }) => {
  return (
    <MyModal show={show} handleClose={handleOpenClose}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <h5>Solid Waste Management</h5>
            <p>
            We have a dedicated team to manage industrial dry waste from inception to its final disposal including mechanical and systematic collection, timely transportation and proper disposal of waste together with vigilant monitoring. The system is in place and successfully operating in various parts of Punjab.
            </p>
          </div>
          <div className="col-md-6 col-lg-6">
            <SlickSlider data={data} />
          </div>
        </div>
      </div>
    </MyModal>
  );
};

export default SolidModal;

const data = [{ src: img3 }];
