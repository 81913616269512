// import logo from './logo.svg';
// import './App.css';
import "./app.scss";
import Header from "./components/header/header.js";
import Hero from "./components/hero/hero.js";
import Services from "./components/services/services.js";
import About from "./components/about/about.js";
// import Journey from './components/ourJourney/ourJourney';
import Clients from "./components/clients/clients";

import Testimonials from "./components/testimonials/testimonials";
import Form from "./components/bottom-form/form";
import Footer from "./components/footer/footer";
import FormModal from "./components/form-modal";
import { useCallback, useState } from "react";

function App() {
  const [open, setOpen] = useState(false);

  const handleOpenClose = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  return (
    <div className="App">
      <div>
        <Header handleOpenClose={handleOpenClose} />
        <Hero handleOpenClose={handleOpenClose} />
        <Services />
        {/* <Journey /> */}
        <About />
        <Clients />
        <Testimonials />
        <Form />
        <Footer />
        <FormModal show={open} handleClose={handleOpenClose} />
      </div>
      
    </div>
  );
}

export default App;
