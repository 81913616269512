import React from "react";
import "./clients.scss";
import Slider from "react-slick";
import {ClientData} from "../data/data";


const Clients = () => {
  const settings = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  return (
    <section id="clients" className="clientsSection" >
      <div className="container">
        <h3 className="sectionTitlte text-center">Our Clients</h3>
      </div>
        <Slider {...settings}>
          
          {ClientData.map((item,i)=> (
            <div>
              <img src={item.pic} alt="" />
            </div>
          ))}
        
        </Slider>
    </section>
  );
};

export default Clients;

