import React from "react";
import "./testimonials.scss";
import Slider from "react-slick";
import iconQuote from "./imgs/icon-quote.svg"
// import user from "./imgs/mdi_user.svg"
import batImg from "./imgs/bat-img.png"
import treetImg from "./imgs/treet-img.png"
import uniLeaverImg from "./imgs/unilever-img.png"
import lumsImg from "./imgs/lums-img.png"
import certificate1 from "./imgs/certificate-img1.jpg"
import certificate2 from "./imgs/certificate-img2.jpg"
// import rentImg from "./imgs/rent-img.png"
import leaseImg1 from './imgs/lease/ride-on-floor-scrubber.jpg';
import leaseImg2 from './imgs/lease/walk-behind-floor-scrubber.jpg';
import leaseImg3 from './imgs/lease/floor-scrubbing-machine.webp';
import leaseImg4 from './imgs/lease/pressure-jet-washer.jpg';
import leaseImg5 from './imgs/lease/vaccum-cleaner.png';
import leaseImg6 from './imgs/lease/window-cleaning-kit.jpg';
import rentImg1 from './imgs/dsource-bowser-img.jpg';
import rentImg2 from './imgs/dsource-compactor-img.jpg';
import Accordion from 'react-bootstrap/Accordion';


import { Tabs, Tab } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";



const Testimonials = () => {
  const settings = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // centerMode: true,

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: false
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {          slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       centerMode: false,
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   }
    // ]
  };

  return (
    <div>
      <section className="certificates-section">
        <div className="container">
          <h3 class="sectionTitlte text-center">Our Certificates</h3>
          <p class="text-center sectionIntroTxt"></p>
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="certificate-holder">
                <img src={certificate1} alt="" />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="certificate-holder">
                <img src={certificate2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonials" className=""  >
        <div className="container">
          <h3 class="sectionTitlte text-center">What Our Clients Say</h3>
          <p class="text-center sectionIntroTxt">This is what clients have been saying after using the great service we do for clients. </p>
        </div>
        <div className="carouselHolder">
          <Slider {...settings}>
            <div className="testimonialHolder">
              <div className="testimonial">
                <img className="quote-icon" src={iconQuote} alt="" />
                <div className="txt">
                  <p>“DSource Pvt Ltd. has earned my support and my recommendation in Corporate Cleaning Service industry, as for over 7 years they have been dedicated to not only upgrade their services but also adding new services to their repertoire. They are open to any concerns we may have and their administrative team is accessible and always striving to go an extra mile to make sure the customer is satisfied.
                    I wish them all the best for all their future endeavors!
                    ”</p>
                </div>
                <div className="user-meta-holder">
                  <div className="img-holder">
                    <img src={batImg} alt="" />
                  </div>
                  <div>
                    <h5>Misbah Ullah</h5>
                    <p>Head of Facilities BAT GBS PM -APME Region</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonialHolder">
              <div className="testimonial">
                <img className="quote-icon" src={iconQuote} alt="" />
                <div className="txt">
                  <p>“I would like to give a shout-out to DSOURCE pvt ltd, for their quality of service and their support extended from last 5 years, especially during COVID-19. The administrative team is well connected and reliable whenever it comes to their clients queries.”</p>
                  <p>All the best!</p>
                </div>
                <div className="user-meta-holder">
                  <div className="img-holder">
                    <img src={treetImg} alt="" />
                  </div>
                  <div>
                    <h5>Ahsan Khawaja</h5>
                    <p>Sr. Manager Supply Chain Services - Treet Cooperation Limited</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonialHolder">
              <div className="testimonial">
                <img className="quote-icon" src={iconQuote} alt="" />
                <div className="txt">
                  <p>“DSource (PVT.) Ltd has introduced advanced methods of maintaining hygiene standards at industry. Entrusting with outsourced services, the company has brought labor economization through machine inductions and better organization of scheduling. Moreover, induction of DSource, simplified the operations to core job instead of excessive work related to payrolls etc. Machine induction, trained labore, dedication to business continuity and high standards of hygiene stands out DSource in industry.
                    ”</p>
                </div>
                <div className="user-meta-holder">
                  <div className="img-holder">
                    <img src={uniLeaverImg} alt="" />
                  </div>
                  <div>
                    <h5>Farhan Qaisar</h5>
                    <p>ASDM Unilever PC Factory & Estate RYK</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonialHolder">
              <div className="testimonial">
                <img className="quote-icon" src={iconQuote} alt="" />
                <div className="txt">
                  <p>“LUMS hired DSource Cleaning Company to maintain campus neat & clean and I am thrilled with the results! From the moment LUMS GAS department contracted with them, their best standard service was exceptional. They were extremely professional, and worked with our schedule to found perfect clean environment 24/7. While DSource cleaners arrived, they were prompt and efficient, and they did an outstanding job. They paid attention to every detail, and LUMS campus was spotless when they finished. What's more, they used eco-friendly cleaning products, which is important to us. I highly recommend DSource Cleaning Company to other corporate companies in need of a top-notch cleaning service.”</p>
                </div>
                <div className="user-meta-holder">
                  <div className="img-holder">
                    <img src={lumsImg} alt="" />
                  </div>
                  <div>
                    <h5>Syed Yawar Abbas Naqvi</h5>
                    <p>Gas Department, LUMS</p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="custom-tabs">
            <Tabs>
              <Tab eventKey="tab-1" title="Rent">
                {/* <div className="row">
                  <div className="col-md-12 col-lg-6 mb-50">
                    <h5 className="fw-700">Specification of Truck Mounted Combined Unit 6000 Liters Suction 4000 liters & Jetting 2000 Liters</h5>
                    <p><strong>1. Tank (Suction):</strong> Locally fabricated tank with 4000 liters capacity. Cylindrical  in shape with full opening dish end with suitable locking system. The Tank shell be fitted with external reinforcing channel ring. 3 sight glasses will be provided at the rear dish end of the tank to alert the operator when the tank is almost full. The complete tank will be fully electrically resistance welded. Tank made of 6mm Sheet.</p>
                    <p><strong>2. Tank (Jetting):</strong> Roto moulded LLDPE tanks modular design 08 Nos. The
                      tanks are specially designed to fit on the side of vacuum tanks. Total capacity 2000 liters.</p>
                    <p><strong>3. Body Design:</strong> Dependent body design.</p>
                    <p><strong>4. Primary Protection:</strong> Suction air outlet of the tank is fitted with automatic float shut off system to discontinue the suction process when the tank is almost full thus avoiding the tank from over filling.</p>
                    <p><strong>5. Secondary Protection:</strong> Locally developed secondary cut off for protection of
                      vacuum pump in case first does not work.</p>
                    <p><strong>6. Pressure Gauge:</strong> Combined gauge for both negative and positive reading of vacuum. Pressure gauge for water jetting.</p>
                    <p><strong>7. Vacuum & Jetting Relief Valve:</strong> Adjustable vacuum relief valve to avoid tank from over vacuum and fix pressure relief valve for positive displacement of air (Italy, UK, USA).</p>
                    <p><strong>8. Suction Line Filter/Water Filter:</strong> To provide Vacuum pump air free of water vapors and other particles./ SS Steiner to Provided on jetting pump free of
                      suspended water supply particles.</p>
                    <p><strong>9. Combination Valve (Suction):</strong> Built in system/ three way valve for Load free starting of engine.</p>
                    <p><strong>10. Silencer (Suction):</strong> Fitted in line with pipe work for noise free operation.</p>
                    <p><strong>11. Rear End:</strong> Full dish opening hydraulically with suitable locking system and sealing 90 Degree.</p>
                    <p><strong>12. Hose reel (Jetting):</strong> Swivel hose reel carrying 100 meter hose of diameter 1”</p>
                    <p><strong>13. Hose Tray:</strong> Suitable Hose tray to be provided along the length of tank for
                      carrying suction hose and other accessories.</p>
                  </div>
                  <div className="col-md-12 col-lg-6 mb-50 text-center">
                    <img src={rentImg} alt="" className="img-fluid" />
                  </div>
                </div>


                <Accordion defaultActiveKey="0" className="">
                  <Accordion.Item eventKey="0" className="text-center ">
                    <Accordion.Header><h5 className="fw-700">Technical Data for Vacuum Pump</h5></Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive">
                        <table class="table table-hover table-bordered">

                          <tbody>
                            <tr>
                              <th scope="row">Country of Origin</th>
                              <td>ITALY/UK/USA</td>
                            </tr>
                            <tr>
                              <th scope="row">Maximum Air Flow (FAD) @ 1500 RPM</th>
                              <td>9000~11500 lit/min</td>
                            </tr>
                            <tr>
                              <th scope="row">Maximum Absolute (Relative Pressure)</th>
                              <td >2.5 Bar</td>
                            </tr>
                            <tr>
                              <th scope="row">Maximum Vacuum without Depression Valve</th>
                              <td >-0.95 Bar</td>
                            </tr>
                            <tr>
                              <th scope="row">Maximum Vacuum with Depression Valve</th>
                              <td >-0.82 Bar</td>
                            </tr>
                            <tr>
                              <th scope="row">Cooling System</th>
                              <td >Air Cooled</td>
                            </tr>
                            <tr>
                              <th scope="row">Lubrication System</th>
                              <td >Built In</td>
                            </tr>
                            <tr>
                              <th scope="row">Lubrication Oil</th>
                              <td >SAW 20 iso 20/iso 68 mineral</td>
                            </tr>
                            <tr>
                              <th scope="row">Driven</th>
                              <td >PTO</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className="text-center ">
                    <Accordion.Header><h5 className="fw-700">Specifications of Water Jetting Pump</h5></Accordion.Header>
                    <Accordion.Body>
                      <div className="table-responsive">
                        <table class="table table-hover table-bordered">
                          <tbody>
                            <tr>
                              <th scope="row">A</th>
                              <td>Flow</td>
                              <td>183 lit/min</td>
                            </tr>
                            <tr>
                              <th scope="row">B</th>
                              <td>Pressure</td>
                              <td>160 Bar</td>
                            </tr>
                            <tr>
                              <th scope="row">C</th>
                              <td>Power Required</td>
                              <td>75 HP</td>
                            </tr>
                            <tr>
                              <th scope="row">D</th>
                              <td>RPM</td>
                              <td>1000</td>
                            </tr>
                            <tr>
                              <th scope="row">E</th>
                              <td>Driven</td>
                              <td>Millat 75 HP Engine</td>
                            </tr>
                            <tr>
                              <th scope="row">F</th>
                              <td>Make pump</td>
                              <td>Italy</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion> */}
                
                <div className="rent-tab-content">
                  <div className="row " >
                    <div className="txt-holder col-md-6 col-lg-6">
                      <h3 style={{"fontWeight":"bold"}}>Bowser</h3>
                      <p>We ensure clearance of drain blockage, elimination of odours and curtailing seasonal infection spread by maintaining a regular cleaning and pump out schedule on a weekly and daily basis.</p>
                      <p>Our services includes:</p>
                      <ul>
                        <li>Drain and PIT Cleaning</li>
                        <li>Septic Tank Cleaning</li>
                        <li>Grease Trap Cleaning and scheduled maintenance services</li>
                        <li>Clearance holding tanks & holiday shack pump outs</li>
                      </ul>
                    </div>
                    <div className="img-holder col-md-6 col-lg-6">
                      <img src={rentImg1} alt="" />
                    </div>
                    <div className="img-holder col-md-6 col-lg-6">
                      <img src={rentImg2} alt="" />
                    </div>
                    <div className="txt-holder col-md-6 col-lg-6">
                      <h3 style={{"fontWeight":"bold"}}>Compactor</h3>
                      <p>Solid dry and wet waste collected and compressed though hydraullic presure compressed garbage trucks. The rocess from inception to its final disposal; including mechanical and systematic collection, timely transpostation and disposal of waste is done mechanically. The compressed Waste is then transported to the designated landfill and is disposed off under the guidelines of local waste management authorities.</p>
                      
                    </div>
                    
                  </div>
                </div>
                

                {/* <div className="text-center mb-50">
                  <h5 className="fw-700">Technical Data for Vacuum Pump</h5>
                  <div className="table-responsive">
                    <table class="table table-hover table-bordered">

                      <tbody>
                        <tr>
                          <th scope="row">Country of Origin</th>
                          <td>ITALY/UK/USA</td>
                        </tr>
                        <tr>
                          <th scope="row">Maximum Air Flow (FAD) @ 1500 RPM</th>
                          <td>9000~11500 lit/min</td>
                        </tr>
                        <tr>
                          <th scope="row">Maximum Absolute (Relative Pressure)</th>
                          <td >2.5 Bar</td>
                        </tr>
                        <tr>
                          <th scope="row">Maximum Vacuum without Depression Valve</th>
                          <td >-0.95 Bar</td>
                        </tr>
                        <tr>
                          <th scope="row">Maximum Vacuum with Depression Valve</th>
                          <td >-0.82 Bar</td>
                        </tr>
                        <tr>
                          <th scope="row">Cooling System</th>
                          <td >Air Cooled</td>
                        </tr>
                        <tr>
                          <th scope="row">Lubrication System</th>
                          <td >Built In</td>
                        </tr>
                        <tr>
                          <th scope="row">Lubrication Oil</th>
                          <td >SAW 20 iso 20/iso 68 mineral</td>
                        </tr>
                        <tr>
                          <th scope="row">Driven</th>
                          <td >PTO</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="text-center mb-50">
                  <h5 className="fw-700">Specifications of Water Jetting Pump</h5>
                  <div className="table-responsive">
                    <table class="table table-hover table-bordered">
                    
                      <tbody>
                        <tr>
                          <th scope="row">A</th>
                          <td>Flow</td>
                          <td>183 lit/min</td>
                        </tr>
                        <tr>
                          <th scope="row">B</th>
                          <td>Pressure</td>
                          <td>160 Bar</td>
                        </tr>
                        <tr>
                          <th scope="row">C</th>
                          <td>Power Required</td>
                          <td>75 HP</td>
                        </tr>
                        <tr>
                          <th scope="row">D</th>
                          <td>RPM</td>
                          <td>1000</td>
                        </tr>
                        <tr>
                          <th scope="row">E</th>
                          <td>Driven</td>
                          <td>Millat 75 HP Engine</td>
                        </tr>
                        <tr>
                          <th scope="row">F</th>
                          <td>Make pump</td>
                          <td>Italy</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div> */}
              </Tab>
              <Tab eventKey="tab-2" title="Lease">
                <div className="row">
                  <div className="col-md-4 col-lg-3">
                    <div className="item">
                      <div className="img-holder">
                        <img src={leaseImg1} alt="Ride on Floor Scrubber" />
                      </div>
                      <h5 className="text-center">Ride on Floor Scrubber</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="item">
                      <div className="img-holder">
                        <img src={leaseImg2} alt="Walk behind floor scrubber" />
                      </div>
                      <h5 className="text-center">Walk behind floor scrubber</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="item">
                      <div className="img-holder">
                        <img src={leaseImg3} alt="Floor scrubbing machine" />
                      </div>
                      <h5 className="text-center">Floor scrubbing machine</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="item">
                      <div className="img-holder">
                        <img src={leaseImg4} alt="Pressure Jet washer" />
                      </div>
                      <h5 className="text-center">Pressure Jet washer</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="item">
                      <div className="img-holder">
                        <img src={leaseImg5} alt="Industrial vacuum cleaner wet and dry" />
                      </div>
                      <h5 className="text-center">Industrial vacuum cleaner wet and dry</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="item">
                      <div className="img-holder">
                        <img src={leaseImg6} alt="Cleaning Window Cleaning Kit" />
                      </div>
                      <h5 className="text-center">Cleaning Window Cleaning Kit</h5>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="tab-3" title="Consume">
              <p>DSource also offers a complete range of local and imported cleaning materials required for any cleaning job. Complete inventory can be delivered to your doorstep according to your requirement. Call us to discuss their usage, availability, and competitive prices. </p>
                <div className="text-center mb-50">
                  
                  <div className="table-responsive" style={{ 'maxWidth': '600px', 'margin': 'auto' }}>
                    <table class="table table-hover table-bordered" style={{ 'textAlign': 'left' }}>
                      <thead>
                        <tr>
                          <th style={{ 'width': '100px' }} scope="col">Sr #</th>
                          <th scope="col">Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Bonus Surf</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Vim Dish Wash </td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Phenyl Liquid</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Phenyl Tablet</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Floor wash Liquid</td>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>Glint (Glass Cleaner)</td>
                        </tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>Hand Wash liquid</td>
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Hand Sanitizer</td>
                        </tr>
                        <tr>
                          <th scope="row">9</th>
                          <td>Toilet Gel</td>
                        </tr>
                        <tr>
                          <th scope="row">10</th>
                          <td>Dettol Cleaner</td>
                        </tr>
                        <tr>
                          <th scope="row">11</th>
                          <td>Bleach </td>
                        </tr>
                        <tr>
                          <th scope="row">12</th>
                          <td>Drain Opener</td>
                        </tr>
                        <tr>
                          <th scope="row">13</th>
                          <td>Air freshners</td>
                        </tr>
                        <tr>
                          <th scope="row">14</th>
                          <td>Air freshner Tablets</td>
                        </tr>
                        <tr>
                          <th scope="row">15</th>
                          <td>Scotch Bright</td>
                        </tr>
                        <tr>
                          <th scope="row">16</th>
                          <td>Dry Mop Complete</td>
                        </tr>
                        <tr>
                          <th scope="row">17</th>
                          <td>Dry Mop Refill</td>
                        </tr>
                        <tr>
                          <th scope="row">18</th>
                          <td>Wet Mop Complete</td>
                        </tr>
                        <tr>
                          <th scope="row">19</th>
                          <td>Wet mop Dori</td>
                        </tr>
                        <tr>
                          <th scope="row">20</th>
                          <td>Commode Brush</td>
                        </tr>
                        <tr>
                          <th scope="row">21</th>
                          <td>Jala Brush</td>
                        </tr>
                        <tr>
                          <th scope="row">22</th>
                          <td>Carpet Brush</td>
                        </tr>
                        <tr>
                          <th scope="row">23</th>
                          <td>Broom  (Bansi Jharoo)</td>
                        </tr>
                        <tr>
                          <th scope="row">24</th>
                          <td>Broom Flower (Phool Jharoo)</td>
                        </tr>
                        <tr>
                          <th scope="row">25</th>
                          <td>Vipers</td>
                        </tr>
                        <tr>
                          <th scope="row">26</th>
                          <td>Glass Viper</td>
                        </tr>
                        <tr>
                          <th scope="row">27</th>
                          <td>Sweepry </td>
                        </tr>
                        <tr>
                          <th scope="row">28</th>
                          <td>Scraper</td>
                        </tr>
                        <tr>
                          <th scope="row">29</th>
                          <td>Gulla Kara</td>
                        </tr>
                        <tr>
                          <th scope="row">30</th>
                          <td>Towel</td>
                        </tr>
                        <tr>
                          <th scope="row">31</th>
                          <td>Duster</td>
                        </tr>
                        <tr>
                          <th scope="row">32</th>
                          <td>Liner all sizes avaible</td>
                        </tr>
                        <tr>
                          <th scope="row">33</th>
                          <td>Tissue Roll/box/ N folds</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </div>

  );
};

export default Testimonials;
