
/////// OURCLIENTS SECTION DETAILS ///////
import ourclient_a from "./imgs/clients/0.png"
import ourclient_b from "./imgs/clients/1.png"
import ourclient_c from "./imgs/clients/2.png"
import ourclient_d from "./imgs/clients/3.png"
import ourclient_e from "./imgs/clients/4.png"
import ourclient_f from "./imgs/clients/5.png"
import ourclient_g1 from "./imgs/clients/6.png"
import ourclient_h1 from "./imgs/clients/7.png"
import ourclient_i1 from "./imgs/clients/8.png"
import ourclient_j1 from "./imgs/clients/9.png"
import ourclient_k1 from "./imgs/clients/10.png"
import ourclient_l from "./imgs/clients/11.png"
import ourclient_m from "./imgs/clients/12.png"
import ourclient_n from "./imgs/clients/13.png"
import ourclient_o from "./imgs/clients/14.png"
import ourclient_p from "./imgs/clients/15.png"
import ourclient_q from "./imgs/clients/16.png"
import ourclient_r from "./imgs/clients/17.png"
import ourclient_s from "./imgs/clients/18.png"
import ourclient_t from "./imgs/clients/19.png"
import ourclient_u from "./imgs/clients/20.png"
import ourclient_v from "./imgs/clients/21.png"

// bannerlogos 
import ourclient_g from "./imgs/clients/3.png"
import ourclient_h from "./imgs/clients/4.png"
import ourclient_i from "./imgs/clients/19.png"
import ourclient_j from "./imgs/clients/9.png"
import ourclient_k from "./imgs/clients/22.png"



// import img1 from "../img/team-member-pic.jpg";
////// TESTIMONIAL SECTION DETAILS///////
// const Testimonail_data = [
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },

//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
// ]


/////// OurClient Section Data ///////
const ClientData = [
  {
      pic:ourclient_a
  },
  {
      pic:ourclient_b
  },
  {
      pic:ourclient_c
  },
  {
      pic:ourclient_d
  },
  {
      pic:ourclient_e
  },
  {
      pic:ourclient_f
  },
  {
        pic:ourclient_g1
    },
    {
        pic:ourclient_h1
    },
    {
        pic:ourclient_i1
    },
    {
        pic:ourclient_j1
    },
    {
        pic:ourclient_k1
    },
    {
        pic:ourclient_l
    },
    {
        pic:ourclient_m
    },
    {
        pic:ourclient_n
    },
    {
        pic:ourclient_o
    },
    {
        pic:ourclient_p
    },
    {
        pic:ourclient_q
    },
    {
        pic:ourclient_r
    },
    {
        pic:ourclient_s
    },
    {
        pic:ourclient_t
    },
    {
        pic:ourclient_u
    },
    {
        pic:ourclient_v
    },
]
const BannerLogostData = [
    {
        pic:ourclient_g
    },
    {
        pic:ourclient_h
    },
    {
        pic:ourclient_i
    },
    
    {
        pic:ourclient_k
    },
    
  ]
// Testimonail_data,
export {ClientData, BannerLogostData};
