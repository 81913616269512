import React from "react";
import MyModal from "../../modal";
import SlickSlider from "../../slick-slider";

import img1 from "./images/janitorial-modal-img/1.jpg";
import img2 from "./images/janitorial-modal-img/2.jpg";
import img3 from "./images/janitorial-modal-img/3.jpg";
import img4 from "./images/janitorial-modal-img/4.jpg";
import img5 from "./images/janitorial-modal-img/5.jpg";

const JanitorialModal = ({ show, handleOpenClose }) => {
  return (
    <MyModal show={show} handleClose={handleOpenClose}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <h5>Janitorial Services</h5>
            <p>
            Janitorial and industrial cleaning services is our core specialized field, where we depute trained and screened staff at your facility with customized plans of specialized machinery. From taking care of day to day cleaning tasks to specialized deep cleaning plans, we handle them all.
            </p>
          </div>
          <div className="col-md-6 col-lg-6">
            <SlickSlider data={data} />
          </div>
        </div>
      </div>
    </MyModal>
  );
};

export default JanitorialModal;

const data = [{ src: img1 }, { src: img2 }, { src: img3 }, { src: img4 }, { src: img5 }];
