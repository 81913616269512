import React from "react";
import MyModal from "../modal";
import "./form-modal.scss";

const FormModal = ({ show, handleClose }) => {
  return (
    <>
      <MyModal show={show} handleClose={handleClose}>
        <div className="col-md-12 " id="bottom-form" style={{"backgroundColor":"transparent"}}>
        
          <div className="form-holder">
            
          <h5 style={{"marginBottom":"20px"}}>Get a Quote</h5>
            <form>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id=""
                  aria-describedby="emailHelp"
                  placeholder="Full name"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  id=""
                  aria-describedby="emailHelp"
                  placeholder="E-mail address"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id=""
                  aria-describedby="emailHelp"
                  placeholder="Subject"
                />
              </div>
              <div class="form-group">
                <textarea
                  class="form-control"
                  id=""
                  rows="5"
                  placeholder="Write us a messege..."
                />
              </div>
              <button type="submit" class="btn btn-primary w-100">
                Submit
              </button>
            </form>
          </div>
        </div>
      </MyModal>
    </>
  )
}

export default FormModal
