import React from "react";
import "./form.scss";
import 'bootstrap/dist/css/bootstrap.css'
import envelop from './imgs/envelop.svg';
import location from './imgs/location.svg';
import tel from './imgs/tel.svg';


const Form = () => {
  
  return (
    <section id="bottom-form" className="" >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <h3 class="sectionTitlte ">How Can We Help You?</h3>
            <p class=" sectionIntroTxt">We'd really love to hear from you so why not drop us an email and we'll get back to you as soon as we can.</p>
            <ul>
              <li className="mb-30">
                <div className="meta">
                  <img src={envelop} alt="" />
                  <a href="mailto:query@dsource.pk">query@dsource.pk</a>
                </div>
              </li>
              <li className="mb-30">
                <div className="meta">
                  <img src={location} alt="" />
                  14 D 1 FCC, Syed Maratib Ali Road, Gulberg IV, Lahore
                </div>
              </li>
              <li className="mb-30">
                <div className="meta">
                  <img src={tel} alt="" />
                  +92 42 35752392-3, +92 300 8439913 , +92 3001555898
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="form-holder ">
            <div className="backDrop"></div>
              <form>
                <div class="form-group">
                  <input type="text" class="form-control" id="" aria-describedby="emailHelp" placeholder="Full name" />
                </div>
                <div class="form-group">
                  <input type="email" class="form-control" id="" aria-describedby="emailHelp" placeholder="E-mail address" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" id="" aria-describedby="emailHelp" placeholder="Subject" />
                </div>
                <div class="form-group">
                  <textarea class="form-control" id="" rows="5" placeholder="Write us a messege..." /> 
                </div>
                <button type="submit" class="btn btn-primary w-100">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
