import React from "react";
import "./footer.scss";
import logo from "./imgs/footer-logo.svg"
import facebook from "./imgs/social/facebook.svg"
import instagram from "./imgs/social/instagram.svg"
import twitter from "./imgs/social/twitter.svg"
import youtube from "./imgs/social/youtube.svg"

// import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.css'

const Footer = () => {
  

  return (
    <footer>
      <div className="top">
        <div className="container text-center">
          <a href="#home" className="logo"><img src={logo} alt="" /></a>
          <p>DSource - A service provider company, specialized in Janitorial services, Waste management solutions and Payroll management.</p>
          <div className="social">
            <a href="#">
              <img src={facebook} alt="" />
            </a>
            <a href="#">
              <img src={instagram} alt="" />
            </a>
            <a href="#">
              <img src={twitter} alt="" />
            </a>
            <a href="#">
              <img src={youtube} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="container text-center">
          <p>© {new Date().getUTCFullYear()} DSOURCE All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
