import React from "react";
import Slider from "react-slick";

const SlickSlider = ({ data }) => {
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            src={data[i].src}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    arrows: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>
        {data?.map((ele, i) => (
          <div key={i}>
            <img
              src={ele.src}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlickSlider;
