import React, { useCallback, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import "./services.scss";
import janitorialServices from "./imgs/janitorial-services.svg";
import solidWasteManagement from "./imgs/solid-waste-management.svg";
import liquidWasteManagement from "./imgs/liquid-waste-management.svg";
import payrollManagement from "./imgs/payroll-management.svg";
import ourValues from "./imgs/ourValues/ourValues.png";
import commitment from "./imgs/ourValues/commitment.svg";
import compliance from "./imgs/ourValues/compliance.svg";
import connections from "./imgs/ourValues/connections.svg";
import Journey from "../ourJourney/ourJourney";
// import Slider from "react-slick";
import JanitorialModal from "./modals/janitorial-modal";
import LiquidModal from "./modals/liquid-modal";
import PayrollModal from "./modals/payroll-modal";
import SolidModal from "./modals/solid-modal";

const Services = () => {
  const [openModal, setOpenModal] = useState({
    janitorial: false,
    liquid: false,
    solid: false,
    payroll: false,
  });

  const handleOpenClose = (open) => {
    setOpenModal({ ...openModal, [open]: !openModal[open] });
  };

  return (
    <section id="services" className="">
      <div className="container">
        <h3 className="sectionTitlte text-center">Our Services</h3>
        <p className="text-center sectionIntroTxt">
          DSource offers operational support system to its clients by managing
          backend processes in an efficient and time bound manner by offering the
          following services
        </p>
        <div className="row mb--70">
          <div className="col-md-6 col-lg-6">
            <div className="box">
              <div className="iconHolder">
                <img src={janitorialServices} alt="Janitorial Services" />
              </div>
              <h5>Janitorial Services</h5>
              <p>
                Janitorial and industrial cleaning services is our core
                specialized field, where we depute trained and screened staff at
                your facility with customized plans of specialized machinery.
                From taking care of day to...
              </p>
              <span
                className="link pointer"
                onClick={() => handleOpenClose("janitorial")}
              >
                Learn More
              </span>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="box">
              <div className="iconHolder">
                <img src={solidWasteManagement} alt="Solid Waste Management" />
              </div>
              <h5>Solid Waste Management</h5>
              <p>
                We have a dedicated team to manage industrial dry waste from
                inception to its final disposal; including mechanical and
                systematic collection, timely transportation and proper disposal
                of waste together...
              </p>

              <span
                className="link pointer"
                onClick={() => handleOpenClose("solid")}
              >
                Learn More
              </span>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="box">
              <div className="iconHolder">
                <img
                  src={liquidWasteManagement}
                  alt="Liquid Waste Management"
                />
              </div>
              <h5>Liquid Waste Management</h5>
              <p>
                After acing janitorial and solid waste management services,
                DSource Now offers Liquid waste contracting services. Our
                vehicles are fully equipped with the latest jetting and suction
                systems along with trained...
              </p>
              <span
                className="link pointer"
                onClick={() => handleOpenClose("liquid")}
              >
                Learn More
              </span>
            </div>
          </div>

          <div className="col-md-6 col-lg-6">
            <div className="box">
              <div className="iconHolder">
                <img src={payrollManagement} alt="Payroll Management" />
              </div>
              <h5>Payroll Management</h5>
              <p>
                Our strategic outsourcing solutions helps you focus on your core
                processes and give access to well managed capital. Flexible
                financial plans that not only ensure labour compliance but also
                manage your...
              </p>
              <span
                className="link pointer"
                onClick={() => handleOpenClose("payroll")}
              >
                Learn More
              </span>
            </div>
          </div>
        </div>

        <div className="ourValuesSection">
          <h3 className="sectionTitlte text-center">Our Vision</h3>
          <p className="text-center sectionIntroTxt">
            We at DSource, envision a world where people, skilled and unskilled,
            hold the power to create opportunities for themselves and for others
          </p>
          <div className="row">
            <div className="col-md-5 col-lg-5">
              <div className="imgHolder">
                <img src={ourValues} alt="" />
              </div>
            </div>
            <div className="col-md-7 col-lg-7">
              <div className="txtHolder">
                <h4 className="fw--700">Our Values</h4>
                <p>
                  We ensure that our clients meet their sustainability goals by
                  offering.
                </p>
                <div className="valuesHolder">
                  <ul>
                    <li className="value clr">
                      <div className="imgHolder">
                        <img src={commitment} alt="compliance" />
                      </div>
                      <div className="txt">
                        <h5 className="fw--600 mb--5">Commitment</h5>
                        <p className="mb--0">
                          To quality workmanship and consistent performance.
                        </p>
                      </div>
                    </li>
                    <li className="value clr">
                      <div className="imgHolder">
                        <img src={compliance} alt="compliance" />
                      </div>
                      <div className="txt">
                        <h5 className="fw--600 mb--5">Compliance</h5>
                        <p className="mb--0">
                          With international standards and corporate
                          requirements.
                        </p>
                      </div>
                    </li>

                    <li className="value clr">
                      <div className="imgHolder">
                        <img src={connections} alt="compliance" />
                      </div>
                      <div className="txt">
                        <h5 className="fw--600 mb--5">Connections</h5>
                        <p className="mb--0">
                          With our clients and employees, built on trust and
                          sustainability.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Journey />

      <JanitorialModal
        show={openModal.janitorial}
        handleOpenClose={() => handleOpenClose("janitorial")}
      />
      <LiquidModal
        show={openModal.liquid}
        handleOpenClose={() => handleOpenClose("liquid")}
      />
      <PayrollModal
        show={openModal.payroll}
        handleOpenClose={() => handleOpenClose("payroll")}
      />
      <SolidModal
        show={openModal.solid}
        handleOpenClose={() => handleOpenClose("solid")}
      />
    </section>
  );
};

export default Services;
