import React from "react";
import "./hero.scss";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";

import { BannerLogostData } from "../data/data";

import bannerImg1 from "./imgs/bannerImg1.png";
import bannerImg2 from "./imgs/bannerImg2.png";
import bannerImg3 from "./imgs/bannerImg3.png";
import whatsappIcon from "./imgs/icon-call.svg";

const Hero = ({ handleOpenClose }) => {
  return (
    <section id="home" className="" style={{ padding: "0px" }}>
      <Carousel fade>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 bg"
            src={bannerImg1}
            alt="First slide"
          />
          {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 bg"
            src={bannerImg2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100 bg"
            src={bannerImg3}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <div className="txtHolder">
        <div className="container">
          <div className="txt">
            <h1>DSource<span className="sub">(Pvt.) Ltd</span></h1>
            <p>
              A service provider company, specialized in Janitorial services, Waste management solutions and Payroll management.
            </p>
            <a href="mailto:query@dsource.pk" style={{"display":"inlineFlex"}}
              className="btn btn-primary getQuoteBtn"
              // onClick={handleOpenClose}
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
      <div className="fone-info">
        <div className="container">
          <div className="info">
            <img className="icon" src={whatsappIcon} alt="" />
            <div>
              <h5 className="uppercase">Call us now:</h5>
              <p>
              {/* https://api.whatsapp.com/send?phone=15551234567 */}
                <a href="https://wa.me/+923001555898">+92 300 1555898</a> |{" "}
                <a href="tel:00923008439913">+92 300 8439913</a>
                
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomLogos">
        <div className="container">
          {BannerLogostData.map((item, i) => (
            <div>
              <img src={item.pic} alt="" />
            </div>
          ))}
        </div>
      </div>
      <a className="fixedWhatsapp" href="https://wa.me/+923001555898"><img className="icon" src={whatsappIcon} alt="" /></a>
    </section>
  );
};

export default Hero;
