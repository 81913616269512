import React from "react";
import MyModal from "../../modal";

import SlickSlider from "../../slick-slider";

import img1 from "./images/payroll-modal-img/1.jpg";
import img2 from "./images/payroll-modal-img/2.jpg";
import img3 from "./images/payroll-modal-img/3.jpg";


const PayrollModal = ({ show, handleOpenClose }) => {
  return (
    <MyModal show={show} handleClose={handleOpenClose}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <h5>Payroll Management</h5>
            <p>
            Our strategic outsourcing solutions helps you focus on your core processes and give access to well managed capital. Flexible financial plans that not only ensure labour compliance but also manage your workforce, while giving you an ease to handle the following:
            </p>
            <ul>
              <li>Direct Salary Deposit</li>
              <li>Monthly Tax Compliance & Filing</li>
              <li>Monitoring the tax laws and implementing changes</li>
              <li>Group Life and Fidelity Insurance Plans</li>
              <li>Provident fund maintenance</li>
              <li>Medical and Insurance Claims Processing</li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-6">
            <SlickSlider data={data} />
          </div>
        </div>
      </div>
    </MyModal>
  );
};

export default PayrollModal;
const data = [{ src: img2 }, { src: img3 } ];
