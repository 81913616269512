import React from "react";
import Counter from "../small-components/counter";
import "./ourJourney.scss";
import 'bootstrap/dist/css/bootstrap.css'
// import locations from './imgs/locations/locations.svg'
import cities from './imgs/locations/cities.png'


const Journey = () => {
  

  return (
    <section className="ourJourney pb--0">
      <div className="container">
        <h3 className="sectionTitlte text-center">
          Our Journey So Far
        </h3>
        <Counter />
      </div>
      <div className="locations">
        <img src={cities} alt="our locations" />
        {/* <div className="txt">
          Lahore  |  Gujranwala  |  Faisalabad  |  Islamabad  |  Multan  |  Rahim Yar Khan
        </div> */}
      </div>
    </section>
  );
};

export default Journey;
