import React from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import './counter.scss';

const Counter = () => {
  return (
    <div className="counterHolder">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-lg-3">
            <div className="content" />
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div className="counter" style={{ height: 100 }}>
                    {isVisible ? <CountUp end={18} /> : null}
                  </div>
                )}
              </VisibilitySensor>
              <span className="title">Projects</span>
            </div>
            <div className="col-md-3 col-lg-3">
            <div className="content" />
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div className="counter" style={{ height: 100 }}>
                    {isVisible ? <CountUp end={874} /> : null}
                  </div>
                )}
              </VisibilitySensor>
              <span className="title">Total headcount</span>
            </div>
            <div className="col-md-3 col-lg-3">
            <div className="content" />
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div className="counter" style={{ height: 100 }}>
                    {isVisible ? <CountUp end={828} /> : null}
                  </div>
                )}
              </VisibilitySensor>
              <span className="title">Unskilled</span>
            </div>
            <div className="col-md-3 col-lg-3">
            <div className="content" />
              <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                  <div className="counter" style={{ height: 100 }}>
                    {isVisible ? <CountUp end={46} /> : null}
                  </div>
                )}
              </VisibilitySensor>
              <span className="title">Skilled</span>
            </div>
        </div>
      </div>



    </div>
  );
};

export default Counter;
