import React from "react";
import "./header.scss";
import NavItem from "../small-components/nav-items.js";
import ScrollspyNav from "react-scrollspy-nav";
import logo from "./imgs/logo.svg";
import humburger from "./imgs/humburger.svg";
import close from "./imgs/close.svg";

// import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.css";

const Header = ({ handleOpenClose }) => {
  const toggleNav = () => {
    const nav = document.getElementById("nav-menu");
    nav.classList.toggle("show");
  };

  return (
    <header className="">
      <div className="container">
        <nav className="nav bd-grid">
          <div>
            <img src={logo} alt="" className="nav__logo" />
          </div>
          <div className="nav__menu" id="nav-menu">
            <ScrollspyNav
              className="nav__menu"
              scrollTargetIds={[
                "home",
                "about2",
                "services",
                "clients",
                "skills",
                "testimonials",
                //   "skills",
                //   "projects",
                //   "certificates",
                //   "contact",
              ]}
              offset={0}
              activeNavClass="active"
              scrollDuration="150"
            >
              <ul className="nav__list">
                <NavItem to="#home" name="Home" />
                <NavItem to="#services" name="Services" />
                <NavItem to="#about2" name="About" />
                <NavItem to="#clients" name="Clients" />
                {/* <NavItem to="#skills" name="Skills" /> */}
                <NavItem to="#testimonials" name="Testimonials" />
                <NavItem to="#!" name="Get a Quote" onClick={handleOpenClose} />

                {/* <NavItem to="#skills" name="Skills" />
              <NavItem to="#projects" name="Projects" />
              <NavItem to="#certificates" name="Certificates" />
              <NavItem to="#contact" name="Contact" /> */}
              </ul>
            </ScrollspyNav>
            <div onClick={toggleNav} className="nav__toggle" id="nav-toggle">
              <img src={close} alt="" />
            </div>
          </div>
          <div className="right">
            <button
              className="btn btn-primary getQuoteBtn"
              // onClick={handleOpenClose}
            >
              Get a Quote
            </button>
            <div onClick={toggleNav} className="nav__toggle" id="nav-toggle">
              <img src={humburger} alt="" />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
