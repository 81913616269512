import React from "react";
import "./about.scss";
import ceImg from "./imgs/ceImg.png";
import founderImg from "./imgs/founderImg.png";

const About = () => {
  

  return (
    <section id="about2" className="" >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="imgHolder">
              <img src={ceImg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="txt">
              <h3 className="sectionTitlte ">Our CE Message</h3>
              <p>Ever since its inception DSource (Pvt.) Ltd has provided quality services to maximize the value for its customers. I have witnessed consistent growth in restructuring our business and we did so with the ever- present priority to safeguard our employees and business partners alike. We pride ourselves on being the first choice when it comes to cleaning and operational solutions.</p>
              <p>As for future effective networking, client’s feedback, adaptive strategies to deal with uprising economic calamities and constant improvement in our procedures are all contributors in further expanding the company and taking Our founder’s vision forward.</p>
              <p>I am confident that the company will continue to scale milestones of excellence for years to come. I want to wish all stakeholders a bright and secure future.</p>
              <p className="uppercase mb--0">
              <em><strong>Dawar Nadeem</strong></em>
                {/* <em><strong>Dawar Nadeem</strong><br/>Message from CE</em> */}
              </p>
            </div>
          </div>
        </div>
        <div className="row founderMsg">
          <div className="col-md-12 col-lg-6 imgCol">
            <div className="imgHolder">
              <img src={founderImg} className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 txtCol">
            <div className="txt">
              <h3 className="sectionTitlte ">Our Founder’s Message</h3>
              <p>I formed this company with a young team and vision to overcome the existing challenges of the service industry. I envisioned long term collaborations with business owners to build value through implementing a practical performance management system that not only caters to cleaning solutions but rather evolves in a holistic facility management system for its clients.</p>
              <p>From the very beginning, my team has been focused on building flexible and sustainable solutions for our clients, which has gained us the repute of excelling at the heart of quality services over the years. With each passing year, our transformation journey has accelerated. Adding new services to our repertoire every year, effective collaborations and incorporating latest machinery to our services not only enhance our performance but also helped in making DSource eminent in service industry, spread over an extensive geographical infrastructure.</p>
              <p>As for the future, i am hopeful for growth and expansion of the company and excellence in waste management solutions. I have both pride and faith in my team. May the force be with you all!</p>
              <p className="uppercase mb--0">
              <em><strong>Nadeem butt</strong></em>
                {/* <em><strong>Nadeem butt</strong><br/>Message from Founder</em> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
