import React from "react";
import MyModal from "../../modal";

import SlickSlider from "../../slick-slider";

import img1 from "./images/liquidWaste-modal-img/1.jpg";
import img2 from "./images/liquidWaste-modal-img/2.jpg";
import img3 from "./images/liquidWaste-modal-img/3.jpg";
import img4 from "./images/liquidWaste-modal-img/4.jpg";

const LiquidModal = ({ show, handleOpenClose }) => {
  return (
    <MyModal show={show} handleClose={handleOpenClose}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <h5>Liquid Waste Management</h5>
            <p>
            After acing janitorial and solid waste management services, DSource Now offers Liquid waste contracting services.
            </p>
            <p>Our vehicles are fully equipped with the latest jetting and suction systems along with trained staff to meet the quality standards when it comes to liquid waste management. We ensure clearance of drain blockage, elimination of odours and curtailing seasonal infection spread by maintaining a regular cleaning and pump out schedule on a weekly and daily basis.</p>
            <p>Given services can be obtained through monthly contract or one time job rentals:</p>
            <ul>
              <li>Drain Cleaning</li>
              <li>PIT Cleaning</li>
              <li>Septic Tank Cleaning</li>
              <li>Grease Trap Cleaning and scheduled maintenance services</li>
              <li>Clearance holding tanks, holiday shack pump outs, etc</li>
              <li>Washing of roads and outer areas</li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-6">
            <SlickSlider data={data} />
          </div>
        </div>
      </div>
    </MyModal>
  );
};

export default LiquidModal;

const data = [{ src: img1 }, { src: img2 }, { src: img3 }, { src: img4 }];
